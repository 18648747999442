<template>
  <div>
    <img :src="home_bg" class="home_bg" />
    <div class="home_first">
      <div class="home_first_bg">
        <div class="home_first_introduction">
          <div class="home_first_introduction_name">
            重塑中医药产业链 创新大健康新生态
          </div>
          <div class="home_first_introduction_content">
            所谓道地药材，又称地道药材，是优质纯真药材的专用名词，
            它是指历史悠久、产地适宜、品种优良、产量宏丰、炮制考究、疗效突出、带有地域特点的药材。
            如甘肃的当归，宁夏的枸杞，青海的大黄，内蒙的黄芪，东北的人参、细辛、五味子，山西的党参，河南的地黄、牛膝、山药、菊花等
            中药的采收时节和方法对确保药物的质量有着密切的关联。因为动植物在其生长发育的不同时期其药用部分所含有效及有害成分各不相同，
            因此药物的疗效和毒副作用也往往有较大差异，故药材的采收必须在适当的时节采集。
          </div>
          <div
            class="home_first_introduction_detail"
            @click="toPage('/introduction')"
          >
            查看详情>>
          </div>
        </div>
        <img
          src="../../static/image/home_tips.png"
          width="22px"
          style="margin-left: 66px"
        />
        <div class="home_second_introduction" id="scrollMedicine">
          <div
            class="home_second_introduction_type"
            v-for="item in medicineList"
            :key="item.name"
          >
            <div class="home_second_introduction_type_bg">
              <div class="home_second_introduction_type_bg_name">
                {{ item.name }}
              </div>
              <div class="home_second_introduction_type_bg_value">
                {{ item.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="home_third_introduction">
          中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。
        </div>
      </div>
    </div>
    <div class="home_map">
      <div class="home_map_bg">
        <img
          src="../../static/image/home_tips.png"
          width="22px"
          style="margin-left: 66px"
        />
        <div class="home_cn_map">
          <div class="home_cn_map_point_sc">
            <div class="home_cn_map_point_sc_Img">
              <div
                v-show="mapIndex == 0"
                style="position: absolute; top: 0px; left: 85px; width: 120px"
              >
                四川中峪道地药材
              </div>
            </div>
          </div>
          <div class="home_cn_map_point_zj">
            <div class="home_cn_map_point_zj_Img">
              <div
                v-show="mapIndex == 1"
                style="position: absolute; top: 0px; left: 85px; width: 120px"
              >
                浙江中峪道地药材
              </div>
            </div>
          </div>
        </div>
        <div class="home_map_about">
          <img
            src="../../static/image/home_map_logo.png"
            width="320px"
            height="220px"
          />
          <el-carousel
            height="250px"
            direction="vertical"
            :interval="5000"
            style="width: 800px"
          >
            <el-carousel-item>
              <div class="home_map_about_right" style="margin-top: 15px">
                <div class="home_map_about_right_en">ABOUT</div>
                <div class="home_map_about_right_cn">四川中峪道地药材</div>
                <div class="home_map_about_right_content">
                  中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心…
                </div>
                <div class="home_map_about_right_tips">
                  <div class="home_map_about_right_tips_all">
                    <div class="home_map_about_right_tips_button">新昌白术</div>
                    <div class="home_map_about_right_tips_button">新昌黄精</div>
                  </div>
                  <div
                    class="home_map_about_right_tips_detail"
                    @click="toPage('/introduction')"
                  >
                    查看更多>
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="home_map_about_right" style="margin-top: 15px">
                <div class="home_map_about_right_en topList">ABOUT</div>
                <div class="home_map_about_right_cn">浙江中峪道地药材</div>
                <div class="home_map_about_right_content">
                  中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心…
                </div>
                <div class="home_map_about_right_tips">
                  <div class="home_map_about_right_tips_all">
                    <div class="home_map_about_right_tips_button">新昌白术</div>
                    <div class="home_map_about_right_tips_button">新昌黄精</div>
                  </div>
                  <div class="home_map_about_right_tips_detail">查看更多></div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="home_second">
      <div class="home_second_bg">
        <img src="../../static/image/home_tips.png" width="22px" />
        <div class="home_second_title">新闻资讯</div>
        <div class="home_second_list">
          <img src="../../static/image/home_news.png" width="560px" />
          <div class="home_second_list_right">
            <div
              v-for="item in newsList"
              :key="item.id"
              class="home_second_list_right_news hover-b"
              @click="toPage('newsListDetail', { id: item.id })"
            >
              <div class="home_second_list_right_news_time">
                {{ item.year }}-{{ item.day }}
              </div>
              <div class="home_second_list_right_news_name">
                {{ item.title }}
              </div>
              <div class="home_second_list_right_news_value">
                {{ item.summary }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home_third">
      <div class="home_third_bg">
        <img
          src="../../static/image/home_tips.png"
          width="22px"
          style="margin-left: 66px"
        />
        <div class="home_third_title">药材合集</div>
        <div class="home_third_list">
          <div
            class="home_third_list_item hover-b"
            @click="toPage('/businessDetail', { name: '薏苡仁' })"
          >
            <img src="../../static/image/防风.jpg" width="276px" />
            <div class="home_third_list_item_name">防风</div>
          </div>
          <div
            class="home_third_list_item hover-b"
            @click="toPage('/businessDetail', { name: '薏苡仁' })"
          >
            <img src="../../static/image/五味子.jpg" width="276px" />
            <div class="home_third_list_item_name">五味子</div>
          </div>
          <div
            class="home_third_list_item hover-b"
            @click="toPage('/businessDetail', { name: '薏苡仁' })"
          >
            <img src="../../static/image/草苁蓉.jpg" width="276px" />
            <div class="home_third_list_item_name">草苁蓉</div>
          </div>
          <div
            class="home_third_list_item hover-b"
            @click="toPage('/businessDetail', { name: '薏苡仁' })"
          >
            <img src="../../static/image/刺五加.jpg" width="276px" />
            <div class="home_third_list_item_name">刺五加</div>
          </div>
        </div>
        <div class="home_third_detail" @click="toPage('/business')">
          查看更多>>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "index",
  data() {
    return {
      home_bg: require("../../static/image/home_bg.png"),
      medicineList: [
        {
          name: "川药",
          value:
            "主要产地四川、西藏等，如川贝母、川芎、黄连。川乌、附子、麦冬、丹参、干姜、白芷、天麻、川牛膝、川楝子、川楝皮、川续断、花椒、黄柏、厚朴、金钱草、五倍子、冬虫夏草、麝香等。",
        },
        {
          name: "广药",
          value:
            "主产地广东、广西、海南及台湾。如阳春砂、广藿香、广金钱草、益智仁、广陈皮、广豆根、蛤蚧、肉桂、桂莪术、苏木、巴戟天、高良姜、八角茴香、化橘红、樟脑、桂枝、槟榔等。",
        },
        {
          name: "云药",
          value:
            "主产地云南。如三七、木香、重楼、茯苓、萝芙木、诃子、草果、马钱子、儿茶等。",
        },
        {
          name: "贵药",
          value:
            "主产地贵州。如天冬、天麻、黄精、杜仲、吴茱萸、五倍子、朱砂等。",
        },
        {
          name: "怀药",
          value:
            "主产地河南，如著名的四大怀药-地黄、牛膝、山药、菊花；天花粉、瓜蒌、白芷、辛夷、红花、金银花、山茱萸等。",
        },
        {
          name: "浙药",
          value:
            "主产地浙江，如著名的浙八味-浙贝母、白术、延胡索、山茱萸、玄参、杭白芍、杭菊花、杭麦冬；温郁金、莪术、杭白芷、栀子、乌梅、乌梢蛇等。",
        },
        {
          name: "关药",
          value:
            "主产地山海关以北、东北三省及内蒙古东部。如人参、鹿茸、细辛、辽五味子、防风、关黄柏、龙胆、平贝母、刺五加、升麻、蛤蟆油、甘草、麻黄、黄芪、赤芍、苍术等。",
        },
        {
          name: "北药",
          value:
            "主产地河北、山东、山西及内蒙古中部。如党参、酸枣仁、柴胡、白芷、北沙参、板蓝根、大青叶、青黛、黄芩、香附、知母、山楂、金银花、连翘、桃仁、苦杏仁、薏苡仁、小茴香、大枣、香加皮、阿胶、全蝎、土鳖虫、滑石、代赭石等。",
        },
        {
          name: "华南药",
          value:
            "主要产地长江以南，南岭以北。如茅苍术、南沙参、太子参、明党参、枳实、枳壳、牡丹皮、木瓜、乌梅、艾叶、薄荷、龟板、鳖甲、蟾蜍、蜈蚣、蕲蛇、石膏、泽泻、莲子、玉竹等。",
        },
        {
          name: "西北药",
          value:
            "主产地丝绸之路的起点西安以西的广大地区（陕、甘、宁、青、新及内蒙古西部）。如大黄、当归、秦艽、秦皮、羌活、枸杞子、银柴胡、党参、紫草、阿魏等。",
        },
        {
          name: "藏药",
          value:
            "主产地青藏高原地区。如著名的四大藏药-冬虫夏草、雪莲花、炉贝母、藏红花，甘松、胡黄连、藏木香、藏菖蒲、余甘子、毛诃子、麝香等。",
        },
      ],
      newsList: [
        {
          id: 0,
          name: "为进一步加强新昌与中峪集团…",
          value:
            "生物制药坚持中医药原创思维，深入发掘中医药精华，是一家传承古代经典名方、名老中医经验方、传统民族药业、新药研发为一体的高新技术制药研发公司…",
          time: "10-01",
        },
        {
          id: 1,
          name: "为进一步加强新昌与中峪集团…",
          value:
            "生物制药坚持中医药原创思维，深入发掘中医药精华，是一家传承古代经典名方、名老中医经验方、传统民族药业、新药研发为一体的高新技术制药研发公司…",
          time: "10-01",
        },
      ],
      mapList: [
        {
          name: "四川",
        },
        {
          name: "浙江",
        },
      ],
      mapIndex: 0,
      scrollInterval1: null,
      scrollInterval2: null,
    };
  },
  computed: {},
  mounted() {
    window.scroll(0, 0);
    let index = 0;
    this.scrollInterval1 = setInterval(() => {
      const scrollMedicine = document.getElementById("scrollMedicine");
      if (index < scrollMedicine.scrollWidth - 1280) {
        scrollMedicine.scroll(index, 0);
        index = index + 1;
      } else {
        scrollMedicine.scroll(scrollMedicine.scrollWidth, 0);
        index = 0;
      }
    }, 20);
    this.scrollInterval2 = setInterval(() => {
      const home_cn_map_point_sc_Img = document.getElementsByClassName(
        "home_cn_map_point_sc_Img"
      )[0];
      if (home_cn_map_point_sc_Img.style.animationPlayState == "paused") {
        home_cn_map_point_sc_Img.style.animationPlayState = "running";
      } else {
        home_cn_map_point_sc_Img.style.animationPlayState = "paused";
      }
      const home_cn_map_point_zj_Img = document.getElementsByClassName(
        "home_cn_map_point_zj_Img"
      )[0];
      if (home_cn_map_point_zj_Img.style.animationPlayState == "paused") {
        home_cn_map_point_zj_Img.style.animationPlayState = "running";
      } else {
        home_cn_map_point_zj_Img.style.animationPlayState = "paused";
      }
      if (this.mapIndex == this.mapList.length - 1) {
        this.mapIndex = 0;
      } else {
        this.mapIndex = this.mapIndex + 1;
      }
    }, 5000);
    this.getList();
    this.initEvent();
  },
  beforeDestroy() {
    clearInterval(this.scrollInterval1);
    clearInterval(this.scrollInterval2);
  },
  methods: {
    initEvent() {
      let flag; // 鼠标按下
      let downX; // 鼠标点击的x下标
      let scrollLeft; // 当前元素滚动条的偏移量
      const nav = document.getElementById("scrollMedicine");
      nav.addEventListener("mousedown", function (event) {
        flag = true;
        downX = event.clientX; // 获取到点击的x下标
        scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
      });
      nav.addEventListener("mousemove", function (event) {
        if (flag) {
          // 判断是否是鼠标按下滚动元素区域
          // 获取移动的x轴
          let moveX = event.clientX;
          // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
          let scrollX = moveX - downX;
          // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
          this.scrollLeft = scrollLeft - scrollX;
        }
      });
      // 鼠标抬起停止拖动
      nav.addEventListener("mouseup", function () {
        flag = false;
      });
      // 鼠标离开元素停止拖动
      nav.addEventListener("mouseleave", function () {
        flag = false;
      });
    },
    getList(categoryId) {
      axios
        .get(
          `https://t2.zyjkjt.net/cms/api/app/news/list?status=PUBLISHED&current=1&size=10&websiteCategoryId=${categoryId}`,
          {
            headers: {
              "Admin-Authorization": localStorage.getItem("token"),
            },
          }
        )
        .then(async (res) => {
          const content = res.data.data.content;
          content.forEach((item) => {
            const updateTime = new Date(item.publishTime);
            item.day =
              updateTime.getDate() > 9
                ? updateTime.getDate()
                : "0" + updateTime.getDate();
            item.year =
              updateTime.getFullYear() +
              "." +
              (updateTime.getMonth() + 1 > 9
                ? updateTime.getMonth() + 1
                : "0" + (updateTime.getMonth() + 1));
            item.title =
              item.newsTitle.length > 30
                ? item.newsTitle.substring(0, 30) + "..."
                : item.newsTitle;
          });
          this.newsList = content.splice(0, 2);
        });
    },
    toPage(path, query) {
      this.$router.push({ path, query });
    },
  },
};
</script>
<style scoped lang="scss">
.home_bg {
  width: 100%;
}
.home_first {
  width: 100%;
  height: 900px;
  background: url("../../static/image/home_first_bg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .home_first_bg {
    width: 1280px;
    margin: 0 auto;
    padding: 100px 0;
    position: absolute;
    top: -200px;
    left: calc((100% - 1280px) / 2);
    .home_first_introduction {
      width: 1080px;
      background-color: #fff;
      text-align: center;
      padding: 50px 100px;
      .home_first_introduction_name {
        font-size: 24px;
        font-weight: 400;
        color: #565759;
        line-height: 33px;
        letter-spacing: 15px;
      }
      .home_first_introduction_content {
        margin-top: 35px;
        font-size: 16px;
        font-weight: 400;
        color: #4c4545;
        line-height: 31px;
        letter-spacing: 1px;
      }
      .home_first_introduction_detail {
        cursor: pointer;
        width: 110px;
        height: 40px;
        background: linear-gradient(
          288deg,
          #56a24c 0%,
          #32663b 100%,
          #32663b 100%
        );
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        margin-top: 25px;
        margin-left: 485px;
      }
      .home_first_introduction_detail:hover {
        background: linear-gradient(
          288deg,
          #32663b 50%,
          #32663b 100%,
          #32663b 100%
        );
      }
    }
    .home_second_introduction {
      width: 1280px;
      margin-top: 55px;
      overflow: auto;
      white-space: nowrap;
      .home_second_introduction_type {
        cursor: pointer;
        margin-right: 24px;
        display: inline-block;
        padding: 10px;
        width: 280px;
        height: 240px;
        border: 1px solid rgba(53, 74, 50, 0.23);
        white-space: normal;
        vertical-align: middle;
        .home_second_introduction_type_bg {
          height: 180px;
          padding: 30px 26px;
          background: rgba(53, 74, 50, 0.06);
          .home_second_introduction_type_bg_name {
            text-align: center;
            font-size: 36px;
            font-weight: 400;
            color: #354a32;
            line-height: 50px;
          }
          .home_second_introduction_type_bg_value {
            margin-top: 24px;
            font-size: 12px;
            font-weight: 400;
            color: #565759;
            line-height: 17px;
          }
        }
      }
    }
    .home_third_introduction {
      margin-top: 70px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #354a32;
      line-height: 32px;
      letter-spacing: 2px;
      padding: 0 150px;
      width: calc(100% -300px);
    }
  }
  .home_first_title {
    width: 100%;
    line-height: 40px;
    .home_first_title_zn {
      font-family: 宋体;
      font-size: 20px;
      font-weight: bold;
      color: #1d1b19;
      display: inline-block;
      position: relative;
    }
    .home_first_title_zn::after {
      content: "";
      position: absolute;
      top: 42px;
      left: 0;
      width: 40px;
      height: 2px;
      background: #9e1307;
    }
    .home_first_title_en {
      cursor: pointer;
      font-size: 14px;
      color: #4d5461;
      float: right;
    }
    .home_first_title_line {
      width: 100%;
      height: 1px;
      background: #e5e5e5;
    }
    .home_first_title_list {
      border-bottom: 1px dashed #abaeb0;
      display: flex;
      height: 67px;
      align-items: center;
      cursor: pointer;
      .home_first_title_list_data {
        margin-right: 15px;
        .home_first_title_list_data_day {
          font-size: 16px;
          color: #9e1307;
          line-height: 0px;
          text-align: center;
        }
        .home_first_title_list_data_year {
          font-size: 8px;
          font-weight: 400;
          color: #9e1307;
          line-height: 11px;
        }
      }
      .home_first_title_list_summary {
        font-size: 14px;
        color: #1d1b19;
        line-height: 67px;
      }
      .home_first_title_list_right {
        line-height: 67px;
        margin-left: auto;
      }
    }
  }
}
.home_map {
  width: 100%;
  height: 1290px;
  background: #f5f7f2;
  position: relative;
  .home_map_bg {
    width: 1280px;
    margin: 0 auto;
  }
  .home_cn_map {
    margin-top: 50px;
    width: 100%;
    height: 784px;
    background: url("../../static/image/home_map.png") no-repeat;
    background-size: contain;
    background-position: 50%;
    position: relative;
    .home_cn_map_point_sc {
      position: absolute;
      bottom: 275px;
      left: 570px;
      .home_cn_map_point_sc_Img {
        width: 106px;
        height: 125px;
        background-size: cover;
        background-image: url("../../static/image/home_map_max.png");
        background-position: center bottom;
        animation: changeImg 5s 4.75s linear infinite;
        color: #565759;
        text-align: right;
        font-size: 14px;
        font-family: 宋体;
        line-height: 106px;
      }
      @keyframes changeImg {
        0% {
          color: #565759;
          background-image: url("../../static/image/home_map_max.png");
        }
        5% {
          color: transparent;
          width: 38px;
          height: 45px;
          margin-left: 30px;
          background-image: url("../../static/image/home_map_min.png");
        }
        10% {
          color: transparent;
          width: 38px;
          height: 45px;
          margin-left: 30px;
          background-image: url("../../static/image/home_map_min.png");
        }
        15% {
          color: #565759;
          width: 106px;
          height: 125px;
          margin-left: 0;
          background-image: url("../../static/image/home_map_max.png");
        }
      }
    }
    .home_cn_map_point_zj {
      position: absolute;
      bottom: 255px;
      right: 330px;
      .home_cn_map_point_zj_Img {
        width: 38px;
        height: 45px;
        background-size: cover;
        background-image: url("../../static/image/home_map_min.png");
        background-position: center bottom;
        animation: changeImg1 5s 4.75s linear infinite;
        color: transparent;
        text-align: right;
        font-size: 14px;
        font-family: 宋体;
        line-height: 106px;
      }
      @keyframes changeImg1 {
        0% {
          color: transparent;
          background-image: url("../../static/image/home_map_min.png");
        }
        5% {
          color: #565759;
          width: 106px;
          height: 125px;
          margin-right: -30px;
          background-image: url("../../static/image/home_map_max.png");
        }
        10% {
          color: #565759;
          width: 106px;
          height: 125px;
          margin-right: -30px;
          background-image: url("../../static/image/home_map_max.png");
        }
        15% {
          color: transparent;
          width: 38px;
          height: 45px;
          margin-right: 0;
          background-image: url("../../static/image/home_map_min.png");
        }
      }
    }
  }
  .home_map_about {
    background: url("../../static/image/home_map_bg.png") no-repeat;
    width: 1100px;
    height: 400px;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    padding: 0 90px;
    .home_map_about_right {
      border-left: 1px solid #e5e5e5;
      margin-left: 67px;
      padding-left: 30px;
      .home_map_about_right_en {
        font-size: 20px;
        font-family: 宋体;
        font-weight: bold;
        color: #355432;
        line-height: 31px;
        position: relative;
      }
      .home_map_about_right_en::before {
        content: "";
        position: absolute;
        top: 0;
        left: -30px;
        width: 2px;
        height: 115px;
        background: #354a32;
      }
      .home_map_about_right_cn {
        font-size: 20px;
        font-family: 宋体;
        font-weight: bold;
        color: #355432;
        line-height: 31px;
      }
      .home_map_about_right_content {
        margin-top: 0;
        font-size: 16px;
        font-family: 宋体;
        font-weight: 400;
        color: #4c4545;
        line-height: 31px;
      }
      .home_map_about_right_tips {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        .home_map_about_right_tips_all {
          display: flex;
        }
        .home_map_about_right_tips_button {
          text-align: center;
          width: 100px;
          height: 32px;
          background: #3d8336;
          border-radius: 16px;
          font-size: 16px;
          font-family: 宋体;
          font-weight: 400;
          color: #ffffff;
          line-height: 32px;
          margin-right: 16px;
        }
      }
    }
  }
}
.home_second {
  width: 100%;
  height: 720px;
  background: url("../../static/image/home_second_bg.png") no-repeat;
  background-size: 100% 100%;
  .home_second_bg {
    width: 1280px;
    margin: 0 auto;
    text-align: center;
  }
  .home_second_title {
    margin-top: 80px;
    font-family: 宋体;
    font-size: 36px;
    font-weight: 400;
    color: #202020;
    line-height: 50px;
  }
  .home_second_list {
    display: flex;
    margin-top: 80px;
    .home_second_list_right {
      margin-left: 60px;
      text-align: left;
      .home_second_list_right_news {
        transition: all 0.5s ease-out;
        padding: 20px;
        cursor: pointer;
        border-bottom: 1px solid #e5e5e5;
        margin-top: 40px;
        .home_second_list_right_news_time {
          font-size: 18px;
          font-weight: 400;
          color: #4d5461;
          line-height: 25px;
          font-family: 宋体;
        }
        .home_second_list_right_news_name {
          font-size: 18px;
          font-family: 宋体;
          font-weight: 400;
          color: #4d5461;
          line-height: 25px;
          margin-top: 8px;
        }
        .home_second_list_right_news_value {
          margin-top: 8px;
          font-size: 14px;
          font-family: 宋体;
          font-weight: 400;
          color: #858585;
          line-height: 23px;
          letter-spacing: 1px;
          margin-bottom: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          // 只要超过宽度就换行，不论中文还是英文
          word-break: break-all;
          //最多展示两行
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
      .home_second_list_right_news:first-child {
        margin-top: 0;
      }
    }
  }
}
.home_third {
  width: 100%;
  background: #f0f2f5;
  padding-bottom: 50px;
  .home_third_bg {
    width: 1280px;
    margin: 0 auto;
  }
  .home_third_title {
    margin-top: 50px;
    font-size: 36px;
    font-family: 宋体;
    font-weight: 400;
    color: #202020;
    line-height: 50px;
    text-align: center;
  }
  .home_third_list {
    margin-top: 50px;
    padding: 45px 33px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    .home_third_list_item {
      cursor: pointer;
      transition: all 0.5s ease-out;
      .home_third_list_item_name {
        text-align: center;
        margin-top: 15px;
        font-size: 20px;
        font-family: 宋体;
        font-weight: bold;
        color: #1d1b19;
        line-height: 50px;
      }
    }
  }
  .home_third_detail {
    cursor: pointer;
    width: 100%;
    height: 64px;
    background: #f9f9f7;
    font-size: 14px;
    font-family: 黑体;
    font-weight: 400;
    color: #252525;
    line-height: 64px;
    text-align: center;
    margin: 50px 0 0 0;
  }
}
/* 隐藏标准的滚动条 */
.home_map_about_scroll::-webkit-scrollbar {
  width: 0;
}
.home_map_about_scroll::-webkit-scrollbar {
  width: 0;
}
.home_second_introduction::-webkit-scrollbar {
  width: 0;
}
.home_second_introduction::-webkit-scrollbar {
  width: 0;
}
/* 隐藏 IE 和 Edge 浏览器的滚动条 */
::-ms-scrollbar {
  width: 0;
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
.topList::before {
  top: 120px !important;
}
</style>
